<template>
  <div>
    <v-menu
      offset-y
      v-model="dataOpen"
      class="notif"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          class="notif ml-4"
          color="secondary"
          overlap
          :content="count > 9 ? '9+' : count"
          :value="count"
        >
          <v-btn
            v-on="on"
            fab
            x-small
            :color="count ? 'secondary' : 'primary'"
          >
            <icon-tooltip
              :disabled="dataOpen"
              bottom
              :class-icon="{'ring': ring, 'notif-icon-primary': !count}"
              :icon-name="'i.Bell'"
              :tooltip-text="$t('t.Notifications')"
            />
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <component :is="notifListComponent" />
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import ClientCache from '@/mixins/client-cache'
export default {
  components: {
    NotifList: () => import('./notif-list'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    count () {
      return this.cache?.doc?.count ?? 0
    },
    notifListComponent () {
      return this.dataOpen ? 'NotifList' : null
    }
  },
  data () {
    return {
      countRef: null,
      cacheType: ClientCache.CacheType.NotifCount,
      id: null,
      dataOpen: false,
      ring: false
    }
  },
  mixins: [ClientCache],
  watch: {
    count (n, o) {
      if (n && n > o) {
        this.ring = true
        setTimeout(() => { this.ring = false }, 1500)
      }
    }
  }
}
</script>

<style lang="stylus">
@css {
  .notif .v-badge__badge, .notif-icon {
    color: hsl(0, 0%, var(--secondary-lightness)) !important
  }

  .notif-icon-primary {
    color: hsl(0, 0%, var(--primary-lightness)) !important
  }
}

.notif
  .ring::before
    animation ring 1.5s ease
    transform-origin top center

@keyframes ring
  0%
    transform rotate(35deg)

  12.5%
    transform rotate(-30deg)

  25%
    transform rotate(25deg)

  37.5%
    transform rotate(-20deg)

  50%
    transform rotate(15deg)

  62.5%
    transform rotate(-10deg)

  75%
    transform rotate(5deg)

  100%
    transform rotate(0deg)
</style>
